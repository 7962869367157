@import 'antd/dist/antd.less';

@primary-color : #1890ff; 
@layout-header-background : #2f8bcd;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #267ab7 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #267ab7 !important;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}


.isoLogoWrapper {
  height: 70px;
  background: rgba(0, 0, 0, 0.3);
  margin: 10px 0 0 0;
  text-align: center;
  overflow: hidden;


}

.ant-table-column-sort{
  font-weight: 900 !important;

}
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: #fff;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f5f6fa !important;
}


.ant-table-thead  > tr > th {
  background: #f5f6fa !important;
  padding: 8px 8px 8px 8px !important;
}

.tabOrders > * .ant-tabs-tab-active {
  background: #d3eaec
}



.daily-order-card  .ant-card-head > * h1, .daily-order-card .ant-card-head > * h2, .daily-order-card .ant-card-head > * h3 ,.daily-order-card .ant-card-head > * h4 ,.daily-order-card .ant-card-head > * h5 {
  color: #fff;
  margin:0 ;
}

.daily-order-card .ant-card-head {
  background-color: #2f8bcd;
}




.tabOrders > * .ant-tabs-tab {
  border-bottom: 1px solid #ccc !important;
  padding: 2px 8px 2px 16px !important;
}

.tabOrders > * .ant-tabs-extra-content{
  width: 100%
}

.search-modal .ant-modal-content {
  background: transparent;
  box-shadow: 0 0px 0px;
}

.search-modal .ant-modal-content .ant-modal-body{
  padding: 0;
}

